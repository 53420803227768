import {
  Typography,
  // Grid,
  Box,
  Button,
  Chip,
  Stack,
  Grid,
  // Tooltip,
} from "@mui/material";
// import { LinkSharp, OpenInNew } from "@mui/icons-material";
import ArrowOutwardRoundedIcon from "@mui/icons-material/ArrowOutwardRounded";
import EmailClipboard from "./emailClipboard";
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import mydp from "../images/mydp.jpg";
import styles from "./index.module.css";

const Intro = () => {
  return (
    <Box
      sx={{
        mt: 10,
      }}
    >
      <div>
        <Stack direction="column" spacing={3}>
          <div>
            <Typography variant="h4" color="primary">
              Hello! I’m Vishnu Teja Kugarthi 👋
            </Typography>
          </div>
          <Grid spacing={1} gap={1}>
            <Stack direction="row" spacing={1}>
              <Chip label="React.js" color="primary" variant="outlined" />
              <Chip label="Node.js" color="primary" variant="outlined" />
              <Chip label="JavaScript" color="primary" variant="outlined" />
            </Stack>
          </Grid>

          <div>
            Hello! I’m Vishnu Teja Kugarthi, a passionate Full-Stack Web
            Developer with over six years of experience in crafting dynamic,
            user-centric web applications. With a strong background in React.js,
            Node.js, HTML, CSS, and JavaScript, I specialize in revamping legacy
            systems, developing modern user interfaces, and delivering scalable
            solutions.
            <p>
              My commitment to continuous learning and collaborating with
              cross-functional teams ensures that I build intuitive,
              high-quality web applications that drive growth and innovation.
              Whether it’s optimizing deployment speeds or enhancing user
              experiences, I thrive on turning complex challenges into impactful
              solutions.
            </p>
          </div>

          <div className={styles.margin_top_16px}>
            <Button
              sx={{
                textTransform: "capitalize",
                fontSize: "16px",
              }}
              href={process.env.REACT_APP_RESUME_URL}
              target="_blank"
              endIcon={<ArrowOutwardRoundedIcon />}
            >
              Resume
            </Button>
            <Button
              sx={{
                textTransform: "capitalize",
                fontSize: "16px",
              }}
              href={process.env.REACT_APP_LINKEDIN_URL}
              target="_blank"
              endIcon={<ArrowOutwardRoundedIcon />}
            >
              LinkedIn
            </Button>
            <Button
              sx={{
                textTransform: "capitalize",
                fontSize: "16px",
              }}
              href={"https://topmate.io/vishnu_teja_kugarthi/"}
              target="_blank"
              endIcon={<ArrowOutwardRoundedIcon />}
            >
              Book a Call
            </Button>
            <Button
              sx={{
                textTransform: "capitalize",
                fontSize: "16px",
              }}
              href={process.env.REACT_APP_BEHANCE_URL}
              target="_blank"
              endIcon={<ArrowOutwardRoundedIcon />}
            >
              Behance
            </Button>
            {/* email clipboard */}
            <EmailClipboard>vishnutejaap@gmail.com</EmailClipboard>
          </div>
        </Stack>
      </div>
    </Box>
  );
};

export default Intro;
